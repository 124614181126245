import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  TransactionStatus as TransactionStatusComponent,
  BasketModalContent
} from '@k3imagine/self-serve-components';
import { PaymentStatus as PaymentStatusType } from '../../../../../types/index';
import GlobalContext from '../../../../../state/GlobalContext';
import { useSelectedTimeSlot } from '../../../../../state/useSelectedTimeSlot';

type PaymentStatusProps = {
  status?: PaymentStatusType;
  orderId?: string | number;
  averageWaitingTimeInSeconds?: number;
  onGoToBasketOverview: Function;
  onGoToStart: Function;
};

const Wrapper = styled.div`
  text-align: center;
  color: black;
  font-size: 1rem;
`;

const PaymentStatus = ({
  status,
  onGoToBasketOverview,
  orderId,
  averageWaitingTimeInSeconds,
  onGoToStart
}: PaymentStatusProps) => {
  const { visualProfileColors, isTimeSlotsEnabled } = useContext(GlobalContext);
  const { selectedTimeSlot } = useSelectedTimeSlot();
  const { t } = useTranslation();

  let averageWaitTimeLabel: string | undefined;
  let averageWaitTime: number | undefined;
  let timeSlot: string | undefined;

  if (isTimeSlotsEnabled) {
    // Time slots supercedes average waiting time. If enabled, the average waiting time is not shown.
    averageWaitTimeLabel = undefined;
    averageWaitTime = undefined;

    if (selectedTimeSlot) {
      const { start, end } = selectedTimeSlot;
      timeSlot = `${t('TimeSlot.TimeSlot')}: ${start} - ${end}`;
    }
  } else {
    averageWaitTimeLabel = `${t('EstimatedWaitingTime')}:`;
    averageWaitTime = averageWaitingTimeInSeconds;
  }

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const renderContent = () => {
    if (status === PaymentStatusType.Success) {
      return (
        <TransactionStatusComponent
          status="Success"
          title={t('Basket.Success')}
          description={t('Basket.PaymentApproved')}
          orderNumberLabel={`${t('Order')}:`}
          orderNumber={orderId}
          timeSlot={timeSlot}
          averageWaitTimeSeconds={averageWaitTime}
          averageWaitingTimeLabel={averageWaitTimeLabel}
          buttonText={t('GoBack')}
          onButtonClicked={() => onGoToStart()}
        />
      );
    }

    let title: string;
    switch (status) {
      case PaymentStatusType.LocationClosed:
        title = t('Basket.LocationClosed');
        break;
      case PaymentStatusType.TimeSlotsLoadFailure:
        title = t('Basket.TimeSlotsLoadFailure');
        break;
      default:
        title = t('Basket.TransactionFailed');
        break;
    }

    return (
      <TransactionStatusComponent
        status="Failed"
        title={title}
        buttonText={t('Basket.BackToOverview')}
        onButtonClicked={() => onGoToBasketOverview()}
      />
    );
  };

  return (
    <BasketModalContent styles={styles} title={t('Basket.Payment')}>
      <Wrapper>{renderContent()}</Wrapper>
    </BasketModalContent>
  );
};

export default PaymentStatus;
