import React, { ChangeEvent, useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BasketModalContent } from '@k3imagine/self-serve-components';

import { Input, Label, Wrapper, Error } from '../../BasketModalWizard.styles';
import GlobalContext from '../../../../../state/GlobalContext';

type UserInfoProps = {
  goNext: Function;
  goBack: Function;
};

const InputWrapper = styled.div`
  margin: auto 1em;
`;
const InputItem = styled.div`
  margin: 16px;
  text-align: left;
`;

const UserAddress = ({ goBack, goNext }: UserInfoProps) => {
  const { t } = useTranslation();
  const {
    visualProfileColors,
    customerAddress,
    updateCustomerAddress
  } = useContext(GlobalContext);
  const [infoMissing, setInfoMissing] = useState(false);

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const isMissing = (stringArray: string[]) => {
    let missing = false;
    stringArray.forEach(inputValue => {
      if (!inputValue) {
        missing = true;
      }
    });
    return missing;
  };

  const validateAddress = () => {
    if (
      isMissing([
        customerAddress.address,
        customerAddress.city,
        customerAddress.postalCode
      ])
    ) {
      setInfoMissing(true);
    } else {
      setInfoMissing(false);
      goNext();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const { value } = target;
    const { name } = target;

    // urrr
    // eslint-disable-next-line
    (customerAddress as any)[name] = value;
    updateCustomerAddress(customerAddress);
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Customer.Address')}
      buttonLabel={t('Continue')}
      buttonIcon="BackArrow"
      onIconButtonClicked={() => goBack()}
      onButtonClicked={() => validateAddress()}
    >
      <Wrapper>
        <InputWrapper>
          <div id="component-container" />
          <InputItem>
            <Label error={infoMissing && customerAddress.address === ''}>
              {t('Customer.Address')}
            </Label>
            <Input
              type="text"
              name="address"
              defaultValue={customerAddress.address}
              onChange={handleChange}
              error={infoMissing && customerAddress.address === ''}
            />
            {infoMissing && customerAddress.address === '' && (
              <Error>{t('Customer.AddressMissing')}</Error>
            )}
          </InputItem>
          <InputItem>
            <Label error={infoMissing && customerAddress.city === ''}>
              {t('Customer.City')}
            </Label>
            <Input
              type="text"
              name="city"
              defaultValue={customerAddress.city}
              onChange={handleChange}
              error={infoMissing && customerAddress.city === ''}
            />
            {infoMissing && customerAddress.city === '' && (
              <Error>{t('Customer.CityMissing')}</Error>
            )}
          </InputItem>
          <InputItem>
            <Label error={infoMissing && customerAddress.postalCode === ''}>
              {t('Customer.PostalCode')}
            </Label>
            <Input
              type="text"
              name="postalCode"
              defaultValue={customerAddress.postalCode}
              onChange={handleChange}
              error={infoMissing && customerAddress.postalCode === ''}
            />
            {infoMissing && customerAddress.postalCode === '' && (
              <Error>{t('Customer.PostalCodeMissing')}</Error>
            )}
          </InputItem>
          <InputItem>
            <Label>{t('Customer.Info')}</Label>
            <Input
              type="text"
              name="extraDeliveryInfo"
              defaultValue={customerAddress.extraDeliveryInfo}
              onChange={handleChange}
            />
          </InputItem>
        </InputWrapper>
      </Wrapper>
    </BasketModalContent>
  );
};

export default UserAddress;
