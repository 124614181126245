import React, { useState, useContext } from 'react';
import { navigate } from 'gatsby';
import { notify, Modal } from '@k3imagine/self-serve-components';

import * as S from './BasketModalWizard.styles';
import GlobalContext from '../../../state/GlobalContext';
import { WizardSteps } from './WizardSteps';
import {
  BasketOverview,
  UserInfo,
  UserAddress,
  TimeSlotSelection,
  PickupOrDelivery,
  PaymentStatus as PaymentStatusComponent,
  Payment,
  BasketValidation
} from '..';
import {
  PaymentStatus,
  SaleResponse,
  GridItemProps,
  BasketItem,
  DeliverySelection
} from '../../../types';
import {
  SelectedTimeSlotState,
  useSelectedTimeSlot
} from '../../../state/useSelectedTimeSlot';
import { AvailableTimeSlotProvider } from '../../../state/useAvailableTimeSlots';

type ModalWizardProps = {
  step: WizardSteps;
  showModal: boolean;
  handleShowModal: Function;
};

const BasketModalWizard = ({
  step,
  showModal,
  handleShowModal
}: ModalWizardProps) => {
  const [currentStep, setCurrentStep] = useState<WizardSteps>(step);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>();
  const [totalAmount, setTotalAmount] = useState<number>(); // this is the final amount that comes from external basket validation
  const [saleResponse, setSaleResponse] = useState<SaleResponse>();

  const {
    setDeliveryOption,
    deliveryItemId,
    freeDeliveryThreshold,
    deliveryAvailable,
    pickupAvailable,
    isTimeSlotsEnabled,
    composerTiles,
    currencyCode,
    clearBasket,
    removeBasketItem,
    addBasketItem,
    basket,
    company,
    rootComposerGroup,
    getComposerTiles
  } = useContext(GlobalContext);

  const {
    clearSelectedTimeSlot
  }: SelectedTimeSlotState = useSelectedTimeSlot();

  let content;

  const hasNoValueSet = (value: any): boolean =>
    value === undefined || value === null;

  const setPickupOption = (pickupOption: string) => {
    setDeliveryOption(pickupOption);

    const deliveryItem: GridItemProps | undefined = composerTiles.find(
      (item: GridItemProps) =>
        item.referenceId === deliveryItemId && item.type === 'Item'
    );
    if (deliveryItemId && deliveryItem) {
      const deliveryItemInBasket:
        | BasketItem
        | undefined = basket.basketItems.find(
        (basketItem: BasketItem) =>
          basketItem.referenceId === deliveryItemId &&
          basketItem.type === 'Item'
      );

      const deliveryPrice: number = deliveryItemInBasket
        ? deliveryItemInBasket.price || 0
        : 0;
      const totalPriceWithoutDelivery: number =
        basket.totalPrice - deliveryPrice;

      if (
        pickupOption === DeliverySelection.Delivery &&
        (hasNoValueSet(freeDeliveryThreshold) ||
          totalPriceWithoutDelivery < freeDeliveryThreshold)
      ) {
        notify({
          message: `${deliveryItem.label} was added for ${deliveryItem.price} ${currencyCode}`,
          type: 'info'
        });

        if (!deliveryItemInBasket) {
          addBasketItem(deliveryItem, 1);
        }
      } else if (
        deliveryItemInBasket &&
        (pickupOption === DeliverySelection.Pickup ||
          hasNoValueSet(freeDeliveryThreshold) ||
          totalPriceWithoutDelivery >= freeDeliveryThreshold)
      ) {
        notify({
          message: `${deliveryItem.label} was removed`,
          type: 'info'
        });

        removeBasketItem(deliveryItemInBasket.id);
      }
    }
  };

  const prevStep = () => {
    return currentStep - 1;
  };

  const nextStep = () => {
    return currentStep + 1;
  };

  const isDeliveryAndPickUpAvailable = () => {
    return deliveryAvailable && pickupAvailable;
  };

  const goToNextStep = () => {
    if (
      nextStep() === WizardSteps.PickupOrDelivery &&
      !isDeliveryAndPickUpAvailable()
    ) {
      if (deliveryAvailable) {
        setPickupOption(DeliverySelection.Delivery);
      }
      if (isTimeSlotsEnabled) {
        setCurrentStep(currentStep + 2);
      } else {
        setCurrentStep(currentStep + 3);
      }
    } else if (nextStep() === WizardSteps.TimeslotSelection) {
      if (isTimeSlotsEnabled) {
        setCurrentStep(currentStep + 1);
      } else {
        setCurrentStep(currentStep + 2);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToTransactionStep = (paymentStatus: PaymentStatus) => {
    setPaymentStatus(paymentStatus);
    setCurrentStep(WizardSteps.PaymentStatus);
  };

  const goToPrevStep = () => {
    if (
      prevStep() === WizardSteps.PickupOrDelivery &&
      !isDeliveryAndPickUpAvailable()
    ) {
      setCurrentStep(currentStep - 2);
    } else if (currentStep === WizardSteps.Payment) {
      // skip Basket Validation step if going back from Payment step
      setCurrentStep(currentStep - 2);
    } else if (prevStep() === WizardSteps.TimeslotSelection) {
      if (isTimeSlotsEnabled) {
        setCurrentStep(currentStep - 1);
      } else {
        setCurrentStep(currentStep - 2);
      }
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const closeModal = () => {
    handleShowModal(false);
    setCurrentStep(WizardSteps.BasketOverview);
    clearSelectedTimeSlot();
  };

  const goToStart = () => {
    closeModal();
    navigate(`/${company}/groups/${rootComposerGroup?.groupUrlName}`);
  };

  const finishSale = (response: {
    paymentStatus: PaymentStatus;
    salesResponse: SaleResponse;
  }) => {
    if (response.paymentStatus === PaymentStatus.Success) {
      clearBasket(true);
    }

    getComposerTiles();
    setPaymentStatus(response.paymentStatus);
    setSaleResponse(response.salesResponse);
    setCurrentStep(WizardSteps.PaymentStatus);
  };

  switch (currentStep) {
    case WizardSteps.BasketOverview:
      content = (
        <BasketOverview goNext={goToNextStep} closeModal={closeModal} />
      );
      break;
    case WizardSteps.PickupOrDelivery:
      content = (
        <PickupOrDelivery
          setPickupOption={setPickupOption}
          goNext={goToNextStep}
          goBack={goToPrevStep}
        />
      );
      break;
    case WizardSteps.TimeslotSelection:
      content = (
        <AvailableTimeSlotProvider>
          <TimeSlotSelection
            goBack={goToPrevStep}
            goNext={goToNextStep}
            goToTransactionStep={goToTransactionStep}
          />
        </AvailableTimeSlotProvider>
      );
      break;
    case WizardSteps.UserInfo:
      content = <UserInfo goNext={goToNextStep} goBack={goToPrevStep} />;
      break;
    case WizardSteps.UserAddress:
      content = <UserAddress goNext={goToNextStep} goBack={goToPrevStep} />;
      break;
    case WizardSteps.BasketValidation:
      content = (
        <BasketValidation
          goNext={(totalAmountData: number) => {
            setTotalAmount(totalAmountData);
            goToNextStep();
          }}
          goBack={goToPrevStep}
          goToStart={goToStart}
          goToPaymentStatus={(paymentStatusResponse: PaymentStatus) => {
            setPaymentStatus(paymentStatusResponse);
            setCurrentStep(WizardSteps.PaymentStatus);
          }}
        />
      );
      break;
    case WizardSteps.Payment:
      content = (
        <Payment
          goNext={finishSale}
          goBack={goToPrevStep}
          totalAmount={totalAmount}
        />
      );
      break;
    case WizardSteps.PaymentStatus:
      content = (
        <PaymentStatusComponent
          orderId={saleResponse?.orderId}
          averageWaitingTimeInSeconds={saleResponse?.averageWaitTimeSeconds}
          status={paymentStatus}
          onGoToBasketOverview={() =>
            setCurrentStep(WizardSteps.BasketOverview)
          }
          onGoToStart={goToStart}
        />
      );
      break;
    default:
      return null;
  }

  return (
    <Modal isHidden={!showModal} onClose={closeModal}>
      <S.ContentWrapper>{content}</S.ContentWrapper>
    </Modal>
  );
};

export default BasketModalWizard;
