import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './Payment.styles';
import GlobalContext from '../../../../../state/GlobalContext';
import { ValitorPayment, OBPayPayment } from './PaymentProviders';
import PaymentExtension from './PaymentProviders/PaymentExtension/PaymentExtension';

type PaymentProps = {
  goNext: Function;
  goBack: Function;
  totalAmount?: number;
};

const Payment = ({ goNext, goBack, totalAmount }: PaymentProps) => {
  const { paymentProvider } = useContext(GlobalContext);
  const { t } = useTranslation();

  const renderPaymentProvider = () => {
    switch (paymentProvider?.type) {
      case 'Valitor':
        return (
          <ValitorPayment
            goNext={goNext}
            goBack={goBack}
            totalAmount={totalAmount}
          />
        );
      case 'GlobalPay':
        return (
          <OBPayPayment
            goNext={goNext}
            goBack={goBack}
            totalAmount={totalAmount}
          />
        );
      case 'PaymentExtension':
        return <PaymentExtension goNext={goNext} />;
      default:
        return (
          <>
            <S.ErrorTitle>{t('Error.Title')}</S.ErrorTitle>
            <S.ErrorSubTitle>{t('Error.Message')}</S.ErrorSubTitle>
          </>
        );
    }
  };

  return renderPaymentProvider();
};

export default Payment;
