import { TimeSlot } from '../types/time-slot.type';
import { Hours, Minutes, Time } from '../types/time.type';

const toDate = (time: Time): Date => {
  const parts = time.split(':');
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);

  const today = new Date();
  today.setHours(hours);
  today.setMinutes(minutes);
  today.setSeconds(0);
  return today;
};

const toTime = (date: Date): Time => {
  const paddedHours = `0${date.getHours()}`;
  const hours: Hours = paddedHours.slice(-2) as Hours;

  const paddedMinutes = `0${date.getMinutes()}`;
  const minutes: Minutes = paddedMinutes.slice(-2) as Minutes;

  const time: Time = `${hours}:${minutes}:00` as Time;
  return time;
};

export const getCurrentTime = (): Time => {
  const today = new Date();

  // Mock date - use for development to fake the time.
  // today.setHours(14);
  // today.setMinutes(33);
  // today.setSeconds(0);

  return toTime(today);
};

export const compareTimes = (timeA: Time, timeB: Time) => {
  const dateA = new Date();
  var parts = timeA.split(':');
  const hoursA = parseInt(parts[0], 10);
  const minutesA = parseInt(parts[1], 10);
  dateA.setHours(hoursA);
  dateA.setMinutes(minutesA);
  dateA.setSeconds(0);

  const dateB = new Date();
  var parts = timeB.split(':');
  const hoursB = parseInt(parts[0], 10);
  const minutesB = parseInt(parts[1], 10);
  dateB.setHours(hoursB);
  dateB.setMinutes(minutesB);
  dateB.setSeconds(0);

  // Returns 1 if greater, -1 if less and 0 if the same.
  if (dateA.getTime() > dateB.getTime()) return 1;
  if (dateA.getTime() < dateB.getTime()) return -1;
  return 0;
};

export const findNearestTime = (time: Time, times: Time[]): Time | null => {
  const startDate = toDate(time);

  const dateTimes = times.map((t: Time) => {
    return toDate(t);
  });

  const startDateEpoch = +startDate;
  let nearestDate: Date | null = null;
  let nearestDiff = Infinity;
  for (let i = 0, n = dateTimes.length; i < n; ++i) {
    const dateEpoch = +dateTimes[i];
    const diff = dateEpoch - startDateEpoch;
    if (diff >= 0 && diff < nearestDiff) {
      nearestDiff = diff;
      nearestDate = dateTimes[i];
    }
  }

  let nearestTime: Time | null = null;
  if (nearestDate !== null) {
    nearestTime = toTime(nearestDate);
  }

  return nearestTime;
};

export const findNearestTimeSlot = (availableTimeSlots: TimeSlot[]) => {
  const currentTime: Time = getCurrentTime();
  const startTimes: Time[] = availableTimeSlots.map(
    (timeSlot: TimeSlot) => timeSlot.start
  );
  const nearestStartTime = findNearestTime(currentTime, startTimes);

  const nearestTimeSlot: TimeSlot | undefined = availableTimeSlots.find(
    (timeSlot: TimeSlot) => timeSlot.start === nearestStartTime
  );

  return nearestTimeSlot;
};

export const formatTimeSlot = (timeSlot: TimeSlot) => {
  const startTimeParts = timeSlot.start.split(':');
  const startHours = startTimeParts[0];
  const startMinutes = startTimeParts[1];

  const endTimeParts = timeSlot.end.split(':');
  const endHours = endTimeParts[0];
  const endMinutes = endTimeParts[1];

  return `${startHours}:${startMinutes} - ${endHours}:${endMinutes}`;
};
