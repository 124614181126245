import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';

import { PaymentType } from '../../../../../../../../../types';
import GlobalContext from '../../../../../../../../../state/GlobalContext';

export type GooglePaymentProps = {
  totalAmount?: number;
  handlePaymentMethodChosen: Function;
  finalizeSale: Function;
  goBack: Function;
  goNext: Function;
};

const GooglePayButton = styled.div`
  width: 100%;
  height: 52px;
  border-radius: 10px;
`;

const GooglePayment = ({
  finalizeSale,
  goBack,
  totalAmount,
  handlePaymentMethodChosen
}: GooglePaymentProps) => {
  const { currencyCode, paymentProvider, shopName } = useContext(GlobalContext);
  let paymentsClient: any;

  const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0
  };

  const tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'globalpayments',
      gatewayMerchantId: paymentProvider?.information?.merchantId
    }
  };

  const allowedCardNetworks = [
    'AMEX',
    'DISCOVER',
    'INTERAC',
    'JCB',
    'MASTERCARD',
    'VISA'
  ];

  const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks
    }
  };

  const cardPaymentMethod = {
    ...baseCardPaymentMethod,
    tokenizationSpecification
  };

  const startPaymentProcess = () => {
    handlePaymentMethodChosen();

    const paymentDataRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [cardPaymentMethod],
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPrice: totalAmount?.toString(),
        currencyCode,
        countryCode: paymentProvider?.information?.merchantCountryCode // K3 Imagine google merchant country code (historically 'GB')
      },
      merchantInfo: {
        merchantName: shopName,
        merchantId: paymentProvider?.information?.googlePayMerchantId // K3 Imagine google merchant id (historically '02671198311113492865')
      }
    };

    paymentsClient
      .loadPaymentData(paymentDataRequest)
      .then((paymentData: any) => {
        const paymentToken =
          paymentData.paymentMethodData.tokenizationData.token;
        finalizeSale({
          digitalWallet: {
            amount: totalAmount || 0,
            token: paymentToken,
            paymentMethod: PaymentType.googlePay
          }
        });
      })
      .catch((err: any) => {
        console.error(err);
        goBack();
      });
  };

  const createGooglePayButton = () => {
    paymentsClient = new google.payments.api.PaymentsClient({
      environment: paymentProvider?.information?.useProductionEnvironment
        ? 'PRODUCTION'
        : 'TEST'
    });

    const isReadyToPayRequest = {
      ...baseRequest,
      allowedPaymentMethods: [baseCardPaymentMethod]
    };

    if (paymentsClient) {
      paymentsClient
        .isReadyToPay(isReadyToPayRequest)
        .then((response: any) => {
          if (response.result) {
            // add a Google Pay payment button
            const button = paymentsClient.createButton({
              onClick: () => startPaymentProcess(),
              buttonSizeMode: 'fill'
            });
            const buttonElement = document.getElementById('google-pay-button');
            if (buttonElement) {
              buttonElement.appendChild(button);
            }
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://pay.google.com/gp/p/js/pay.js';
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener('load', createGooglePayButton);
  }, []);

  return <GooglePayButton id="google-pay-button" />;
};

export default GooglePayment;
