import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';

const api = axios.create({
  baseURL: `${process.env.API_URL}/api/${process.env.API_VERSION}`,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Here we can add everything we want to be used across all requests
const config: AxiosRequestConfig = {
  baseURL: `${process.env.API_URL}/api/${process.env.API_VERSION}`,
  headers: {
    'Content-Type': 'application/json'
  }
};
// Header values derived from session storage are set
if (typeof window !== 'undefined') {
  const tenantId = sessionStorage.getItem('tenantId');
  if (tenantId !== null) config.headers.tenantId = tenantId;

  const shopId = sessionStorage.getItem('shopId');
  if (shopId !== null) config.headers.shopId = shopId;

  config.headers['Request-Id'] = uuidv4();
}

api.interceptors.request.use(
  conf => {
    const tenantId = sessionStorage.getItem('tenantId');
    if (tenantId !== null) conf.headers.TenantId = tenantId;

    const shopId = sessionStorage.getItem('shopId');
    if (shopId !== null) conf.headers.ShopId = shopId;

    conf.headers['Request-Id'] = uuidv4();
    return conf;
  },
  error => Promise.reject(error)
);

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    return error.response;
  }
);

/* Add a response/request interceptor that counts the requests outstanding, this is to ensure
  that basket transactions etc are completed before the end sale transaction is started, so that the totals match
*/
let numberOfApiCallsPending = 0;

api.interceptors.request.use(
  function (config) {
    numberOfApiCallsPending += 1;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    numberOfApiCallsPending -= 1;
    return response;
  },
  function (error) {
    numberOfApiCallsPending -= 1;
    return Promise.reject(error);
  }
);

const outstandingAPICalls = () => {
  return numberOfApiCallsPending !== 0;
};

export default api;
export { outstandingAPICalls };
