import React, { createContext, useContext, useEffect, useState } from 'react';
import * as HttpStatus from 'http-status-codes';

import { getAvailableTimeSlotsByShop } from '../services/time-slot.service';
import { TimeSlot } from '../types/time-slot.type';

export type AvailableTimeSlotState = {
  status: 'LOADING' | 'LOADED' | 'ERROR';
  availableTimeSlots: TimeSlot[];
};

const TimeSlotContext = createContext(null);

const useAvailableTimeSlots = () => {
  const context = useContext(TimeSlotContext);

  if (!context) {
    throw new Error(
      'useAvailableTimeSlots must be used within AvailableTimeSlotProvider'
    );
  }
  return context;
};

const AvailableTimeSlotProvider = (props: any) => {
  const [state, setState] = useState<AvailableTimeSlotState>({
    status: 'LOADING',
    availableTimeSlots: []
  });

  useEffect(() => {
    setState({
      status: 'LOADING',
      availableTimeSlots: []
    });

    (async (): Promise<void> => {
      const result = await getAvailableTimeSlotsByShop();

      if (result.status !== HttpStatus.OK) {
        console.error('Failed to load time slots.', result);

        setState({
          status: 'ERROR',
          availableTimeSlots: []
        });
      } else {
        setState({
          status: 'LOADED',
          availableTimeSlots: result.data
        });
      }
    })();
  }, []);

  return <TimeSlotContext.Provider value={state} {...props} />;
};

export { AvailableTimeSlotProvider, useAvailableTimeSlots };
