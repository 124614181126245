import { useState, useEffect } from 'react';
import { getAllComposerTiles } from '../services/composer.service';
import { getShop } from '../services/shops.service';
import { getValitorConfig, getOBPayConfig } from '../services/payment.service';
import {
  Config,
  GridItemProps,
  GlobalStateType,
  ComposerGroupItem,
  RootComposerGroup,
  VisualProfileColors,
  Font,
  PaymentProvider
} from '../types';
import i18n from '../locales/i18n';
import api from '../utils/api';

const DEFAULT_LOCALE = 'en-gb';

const mapGridItems = (composerTiles: ComposerGroupItem[] = []) =>
  composerTiles.map(
    ({
      displayName,
      priceRange,
      groupUrlName,
      isCustomizable,
      ...rest
    }: ComposerGroupItem) => ({
      ...rest,
      label: displayName,
      price: priceRange ? priceRange.from : 0,
      value: {
        groupUrlName: groupUrlName || '',
        isCustomizable
      }
    })
  );

const useGlobalState = (): GlobalStateType => {
  const [composerTiles, setComposerTiles] = useState<GridItemProps[]>([]);
  const [
    rootComposerGroup,
    setRootComposerGroup
  ] = useState<RootComposerGroup>();
  const [isFetching, setFetching] = useState<boolean>(true);
  const [currencyCode, setCurrencyCode] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [shopName, setShopName] = useState<string>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<Config>();
  const [splashTimer, setSplashTimer] = useState<boolean>(true);
  const [tenantId, setTenantId] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [shopId, setShopId] = useState<string>('');
  const [deliveryAvailable, setDeliveryAvailable] = useState<boolean>(true);
  const [pickupAvailable, setPickupAvailable] = useState<boolean>(true);
  const [deliveryItemId, setDeliveryItemId] = useState<number>(0);
  const [freeDeliveryThreshold, setFreeDeliveryThreshold] = useState<number>(0);
  const [isTimeSlotsEnabled, setTimeSlotsEnabled] = useState<boolean>(false);
  const [initLoadCompleted, setInitLoadCompleted] = useState<boolean>(false);
  const [defaultLocale, setDefaultLocale] = useState<string>(DEFAULT_LOCALE);
  const [
    visualProfileColors,
    setVisualProfileColors
  ] = useState<VisualProfileColors>();
  const [visualProfileFonts, setVisualProfileFonts] = useState<Font>();
  const [paymentProvider, setPaymentProvider] = useState<PaymentProvider>();

  const getShopConfig = async () => {
    const shop = await getShop();
    const { data: shopData } = shop;

    const {
      imageUrl: image,
      currency,
      languages,
      config: configData,
      profile,
      defaultPaymentProvider,
      description,
      useTimeSlots
    } = shopData;
    const {
      deliveryItemId: deliveryItem,
      freeDeliveryThreshold: deliveryThreshold,
      deliveryAvailable: delivery,
      pickupAvailable: pickup
    } = shopData;
    const defaultLanguage = (
      languages?.find(lang => lang.defaultLanguage)?.languageCode ||
      DEFAULT_LOCALE
    ).split('-')[1];

    setImageUrl(
      image ||
        'https://k3imagine.blob.core.windows.net/selfserv/K3imagine-logo-dark.png'
    );
    setShopName(description);
    setCurrencyCode(currency ? currency.currencyCode : '');
    setShopName(description);
    setDeliveryItemId(deliveryItem);
    setFreeDeliveryThreshold(deliveryThreshold);
    setDeliveryAvailable(delivery);
    setPickupAvailable(pickup);
    setTimeSlotsEnabled(useTimeSlots);
    setDefaultLocale(defaultLanguage);
    setConfig(configData);
    setVisualProfileColors(profile?.color);
    setVisualProfileFonts(profile?.font);

    if (defaultPaymentProvider === 'Valitor') {
      const valitorMerchantData = await getValitorConfig();
      setPaymentProvider({
        type: defaultPaymentProvider,
        information: valitorMerchantData.data
      });
    } else if (defaultPaymentProvider === 'GlobalPay') {
      const obPayMerchantData = await getOBPayConfig();
      setPaymentProvider({
        type: defaultPaymentProvider,
        information: obPayMerchantData.data
      });
    } else {
      setPaymentProvider({ type: defaultPaymentProvider });
    }

    i18n.changeLanguage(defaultLanguage);
  };

  const getComposerTiles = async () => {
    const responses = await Promise.all([getAllComposerTiles()]);
    if (responses.every((r: any) => r.status < 400 && r.data)) {
      const [composer] = responses;
      const { data: composerData } = composer;
      const gridItems: GridItemProps[] = mapGridItems(composerData.tiles);

      setRootComposerGroup(composerData.rootComposerGroup);
      setComposerTiles(gridItems);

      setFetching(false); // This needs to be the last call. Otherwise an exception is thrown. richard.obrien@k3btg.com
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    const tenantInfo = async () => {
      const companyName: string = window.location.href.split('/')[3];
      setCompany(companyName);
      const pathMap = await (await api.get(`pathmap/${companyName}`))?.data;

      if (pathMap && pathMap.tenantId && pathMap.shopId) {
        setTimeout(() => {
          setSplashTimer(false);
        }, 3000);

        sessionStorage.setItem('tenantId', pathMap.tenantId.toString());
        sessionStorage.setItem('shopId', pathMap.shopId.toString());

        setTenantId(pathMap.tenantId.toString());
        setShopId(pathMap.shopId.toString());

        // This stops the double loading of the global state
        if (initLoadCompleted) {
          setLoading(false);
          setFetching(false);
        } else {
          getShopConfig();
          getComposerTiles();
          setInitLoadCompleted(true);
        }
      } else {
        setError(true);
      }
    };
    tenantInfo();
  }, [isFetching]);
  return {
    error,
    shopId,
    loading,
    tenantId,
    imageUrl,
    currencyCode,
    composerTiles,
    rootComposerGroup,
    deliveryItemId,
    freeDeliveryThreshold,
    deliveryAvailable,
    pickupAvailable,
    isTimeSlotsEnabled,
    company,
    splashTimer,
    defaultLocale,
    config,
    visualProfileColors,
    visualProfileFonts,
    paymentProvider,
    shopName,
    getComposerTiles
  };
};

export default useGlobalState;
