import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BasketList,
  BasketModalContent
} from '@k3imagine/self-serve-components';
import GlobalContext from '../../../../../state/GlobalContext';
import { LayoutContext } from '../../../../Layout/Layout';
import {
  GridItemProps,
  NumberPickerActionType,
  BasketItem
} from '../../../../../types';
import { debounce } from 'lodash';



type BasketOverviewProps = {
  goNext: Function;
  closeModal: Function;
};

const BasketOverview = ({ goNext, closeModal }: BasketOverviewProps) => {
  const {
    basket,
    updateBasketItem,
    removeBasketItem,
    currencyCode,
    defaultLocale,
    composerTiles,
    basketLoading,
    visualProfileColors,
    clearBasket
  } = useContext(GlobalContext);
  const { setShowProductDetailModal, setProductDetailModalInfo } = useContext(
    LayoutContext
  );

  const { t } = useTranslation();

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const priceSummary = [
    { id: 0, label: t('Basket.Total'), value: basket.totalPrice, bold: true }
  ];

  const emptyBasket = () => {
    clearBasket();
    closeModal();
  };

  const handleNumberPickerChange = ({
    actionType,
    item
  }: {
    actionType: NumberPickerActionType;
    item: BasketItem;
  }) => {
    if (actionType === '+') {
      item.quantity = item.quantity + 1
      debouncehHandler(item)
    }
    if (actionType === '-') {
      if (item.quantity && item.quantity <= 1) {
        removeBasketItem(item.id);
      } else {
        item.quantity = item.quantity - 1;
        debouncehHandler(item)
      }
    }
  };

  const debouncehHandler = debounce((info : any) =>{
    updateBasketItem(info.id, info.quantity, info.customization);
  },1000)

   const mapBasketItems = (basketItems: BasketItem[]) => {
    if (basketItems && basketItems.length > 0) {
      return basketItems.map(i => {
        const tilesInfo = composerTiles.find(
          tile => tile.referenceId === i.referenceId && tile.type === i.type
        );
        const newItem = { ...tilesInfo, ...i };
        return newItem;
      });
    }
    return [];
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Basket.Basket')}
      buttonLabel={t('Continue')}
      buttonIcon="Trash"
      buttonDisabled={
        (basket.basketItems && basket.basketItems.length <= 0) ||
        basketLoading
      }
      onIconButtonClicked={() =>
        basket.totalQuantity > 0 ? emptyBasket() : null
      }
      onButtonClicked={() =>
        basket.totalQuantity > 0 && !basketLoading ? goNext() : null
      }
    >
      <BasketList
        emptyListText={t('Basket.NoItems')}
        basketItems={mapBasketItems(basket.basketItems)}
        onNumberPickerChanged={(info: {
          actionType: NumberPickerActionType;
          item: BasketItem;
        }) => handleNumberPickerChange(info)}
        onRemoveClicked={({ item }: { item: GridItemProps }) => {
          removeBasketItem(item.id);
        }}
        onEditClicked={({ item }: { item: GridItemProps }) => {
          setProductDetailModalInfo({ product: item, isNew: false });
          setShowProductDetailModal(true);
        }}
        onRowClicked={({ item }: { item: GridItemProps }) => {
          setProductDetailModalInfo({ product: item, isNew: false });
          setShowProductDetailModal(true);
        }}
        summaryListItems={priceSummary}
        currencyCode={currencyCode}
        locale={defaultLocale}
      />
    </BasketModalContent>
  );
};

export default BasketOverview;
