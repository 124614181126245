import { TileType } from '.';

export enum PaymentType {
  manualCard = 0,
  applePay = 1,
  googlePay = 2
}

export enum PaymentStatus {
  Success = 'Success',
  Failed = 'Failed',
  LocationClosed = 'LocationClosed',
  TimeSlotsLoadFailure = 'TimeSlotLoadFailure'
}

export enum DeliverySelection {
  Delivery = 'delivery',
  Pickup = 'pickup'
}

export type SaleItem = {
  localStorageBasketItemIdentifier?: number;
  referenceId: number;
  type: TileType;
  quantity: number;
  orderDetail?: string;
  bundleSelections?: BundleSelection[];
  modifierSelections?: ModifierSelection[];
};

type BundleSelection = {
  referenceId: number;
  bundleGroupId: number;
};

type ModifierSelection = {
  modifierId: number;
  quantity: number;
};

export type Sale = {
  paymentMethod: PaymentType;
  items: Array<SaleItem>;
};

export type SaleResponse = {
  orderId?: number;
  averageWaitTimeSeconds?: number;
};

export type PaymentBreakdown = {
  totalAmount: number;
  totalTaxAmount: number;
  subtotal: number;
  availableBasketItems?: SaleItem[];
  unavailableBasketItems?: SaleItem[];
};

export type ValitorPaymentResponse = {
  paymentMethod?: number;
  PaymentInformation: string;
  Amount: string;
  AuthorizationNumber: string;
  TransactionNumber: string;
  CardType: string;
  CardTypeCode: string;
  Date: string;
  AgreementNumber: string;
  ErrorNumber: string;
  ErrorMessage: string;
};

export type ValitorPaymentResponseInfo = {
  paymentMethod?: number;
  authorizationNumber: string;
  transactionNumber: string;
  cardType: string;
  agreementNumber: string;
};

export type DigitalWalletResponseInfo = {
  amount: number;
  token: string;
  paymentMethod: PaymentType;
};

export type ValitorSignatureResponse = {
  merchantReferenceId: string;
  signature: string;
};
