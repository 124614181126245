import React, { useState, useEffect, useContext } from 'react';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import {
  Grid as GridComponent,
  notify
} from '@k3imagine/self-serve-components';
import GlobalContext from '../../state/GlobalContext';
import {
  UpdateBasketItemInfo,
  GridItemProps,
  BasketItem,
  Breadcrumb,
  RootComposerGroup
} from '../../types';
import { LayoutContext } from '..';
import { debounce } from 'lodash';


const getTotalQuantity = (
  product: GridItemProps,
  basketItems: BasketItem[]
) => {
  let totalQuantity = 0;
  basketItems.forEach(basketItem => {
    if (
      basketItem.referenceId === product.referenceId &&
      basketItem.type === product.type
    ) {
      totalQuantity += basketItem.quantity;
    }
  });

  return totalQuantity;
};

const updateQuantity = (
  gridItems: GridItemProps[],
  basketItems: BasketItem[]
) => {
  return gridItems.map(item => ({
    ...item,
    quantity: getTotalQuantity(item, basketItems)
  }));
};

const filterBySearch = (
  search: string,
  allComposerTiles: GridItemProps[]
): GridItemProps[] => {
  let mappedGridItems: GridItemProps[] = [];
  allComposerTiles.forEach(item => {
    if (
      (item.label || '')
        .toLowerCase()
        .match(new RegExp(search.toLowerCase(), 'g')) &&
      !mappedGridItems.find(
        i => i.referenceId === item.referenceId && i.type === item.type
      )
    ) {
      mappedGridItems = [...mappedGridItems, item];
    }
  });

  return mappedGridItems;
};

const filterByGroupId = (
  groupId: string | number,
  allComposerTiles: GridItemProps[]
): GridItemProps[] => {
  return allComposerTiles.filter(
    item => item.composerGroupId === Number(groupId)
  );
};

const getBreadcrumbsFromUrl = (
  composerTiles: GridItemProps[],
  pathname: string,
  company?: string,
  rootGroup?: RootComposerGroup
): Breadcrumb[] => {
  const groupsFromPath: string[] = pathname
    .split('/')
    .filter(p => p !== '' && p !== 'groups' && p !== company);

  let newBreadcrumbs: Breadcrumb[] = [];
  let foundGroup;

  groupsFromPath.forEach(groupUrlName => {
    switch (groupUrlName) {
      case rootGroup?.groupUrlName:
        newBreadcrumbs = [
          ...newBreadcrumbs,
          {
            id: uniqid(),
            label: rootGroup?.displayName || '',
            value: {
              groupUrlName: rootGroup?.groupUrlName,
              referenceId: rootGroup?.id
            }
          }
        ];
        return;
      default:
        foundGroup = composerTiles.find(
          g => g.value.groupUrlName === groupUrlName
        );
        // if we find a url group that does not match a groupUrlName, we will navigate back to root group
        if (!foundGroup) {
          navigate(`/${company}/groups/${rootGroup?.groupUrlName}`);
          return;
        }
        newBreadcrumbs = [
          ...newBreadcrumbs,
          {
            id: uniqid(),
            label: foundGroup?.label || '',
            value: {
              groupUrlName: foundGroup.value.groupUrlName,
              referenceId: foundGroup.referenceId
            }
          }
        ];
    }
  });

  return newBreadcrumbs;
};

export const Grid = ({ location }: { location: Location }) => {
  const [filteredItems, setFilteredItems] = useState<GridItemProps[]>([]);
  const [gridItems, setGridItems] = useState<GridItemProps[]>([]);
  const {
    search,
    setSearch,
    setShowProductDetailModal,
    setProductDetailModalInfo,
    setShowBasketModal,
    setBreadcrumbs
  } = useContext(LayoutContext);
  const {
    basket,
    currencyCode,
    composerTiles,
    getBasketItems,
    removeBasketItem,
    addBasketItem,
    updateBasketItem,
    company,
    rootComposerGroup,
    defaultLocale,
    visualProfileColors
  } = useContext(GlobalContext);
  const { t } = useTranslation();

  const styles = {
    item: {
      group: {
        backgroundColor: visualProfileColors?.categoryTile?.background,
        labelTextColor: visualProfileColors?.categoryTile?.text
      }
    }
  };

  const newBreadcrumbs: Breadcrumb[] = getBreadcrumbsFromUrl(
    composerTiles,
    location.pathname,
    company,
    rootComposerGroup
  );

  // last breadcrumb is the current group we want to see
  const groupId =
    newBreadcrumbs.length > 0
      ? newBreadcrumbs[newBreadcrumbs.length - 1].value.referenceId
      : rootComposerGroup?.id;

  useEffect(() => {
    setBreadcrumbs(newBreadcrumbs);

    let items: GridItemProps[];
    if (search?.length > 0) {
      items = filterBySearch(search, composerTiles);
    } else {
      items = filterByGroupId(groupId, composerTiles);
    }
    setFilteredItems(items);
  }, [composerTiles, search, groupId]);

  useEffect(() => {
    setGridItems(updateQuantity(filteredItems, basket.basketItems));
  }, [basket, filteredItems]);

  const handleNumberPickerChange = ({
    actionType,
    item
  }: UpdateBasketItemInfo) => {
    if (actionType === '+') {
      if (item.value.isCustomizable) {
        setProductDetailModalInfo({ product: item, isNew: true });
        setShowProductDetailModal(true);
      } else {
        item.quantity = Number(item.quantity) + 1
        debouncehHandler(item);
      }
    }
    if (actionType === '-') {
      const basketItems = getBasketItems(item.referenceId, item.type);
      if (basketItems.length > 1) {
        notify({
          message: 'Please choose which item you want to remove.',
          type: 'error'
        });
        setShowBasketModal(true);
      } else if (basketItems.length === 1) {
        if (item.quantity && item.quantity <= 1) {
          removeBasketItem(basketItems[0].id);
        } else {
          updateBasketItem(basketItems[0].id, basketItems[0].quantity - 1);
        }
      }
    }
  };

  const debouncehHandler = debounce((info : any) =>{
    info.newQuantity = info.quantity
    addBasketItem(info ,{}, info.newQuantity);
  },1000)

  return (
    <>
      <GridComponent
        styles={styles}
        gridItems={gridItems}
        locale={defaultLocale}
        currencyCode={currencyCode}
        emptyGridMessage={t('EmptyGridMessage')}
        outOfStockText={t('SoldOut')}
        onNumberPickerChanged={(info: UpdateBasketItemInfo) =>
          handleNumberPickerChange(info)
        }
        onItemClicked={(item: GridItemProps) => {
          if (item.type === 'Group' && item.referenceId) {
            setSearch('');
            navigate(`${location.pathname}/${item.value.groupUrlName}`);
          } else if (item.hasInfo) {
            setProductDetailModalInfo({ product: item, isNew: true });
            setShowProductDetailModal(true);
          }
        }}
      />
    </>
  );
};

export default Grid;
