import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ComponentProps } from 'react';
import { FlagIcon } from '../components/SelfServe/components/Basic'; // '@k3imagine/self-serve-components';
/* eslint-disable global-require */

export type FlagIconProps = ComponentProps<typeof FlagIcon>['code'];

const resources = {
  gb: require('./en-gb/translations.json'),
  is: require('./is-is/translation.json')
};

export const availableLanguages: { code: FlagIconProps }[] = [
  { code: 'gb' },
  { code: 'is' }
];

export const initialLanguage: { code: FlagIconProps; name: string } = {
  code: 'gb',
  name: 'English'
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    resources,
    lng: initialLanguage.code,
    fallbackLng: 'gb',

    // have a common namespace used around the full app
    ns: ['Translations'],
    defaultNS: 'Translations',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
