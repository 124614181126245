export type PaymentProps = {
  totalAmount?: number;
  goNext: Function;
  goBack: Function;
};

export enum PaymentMethod {
  Card = 'Card',
  ApplePay = 'ApplePay',
  GooglePay = 'GooglePay'
}
