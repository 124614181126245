import React, { ChangeEvent, useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BasketModalContent } from '@k3imagine/self-serve-components';

import GlobalContext from '../../../../../state/GlobalContext';
import { Error, Input, Label, Wrapper } from '../../BasketModalWizard.styles';

type UserInfoProps = {
  goNext: Function;
  goBack: Function;
};

const InputWrapper = styled.div`
  margin: auto 1em;
`;
const InputItem = styled.div`
  margin: 16px;
  text-align: left;
`;

const UserInfo = ({ goBack, goNext }: UserInfoProps) => {
  const { t } = useTranslation();
  const { visualProfileColors, customerInfo, updateCustomerInfo } = useContext(
    GlobalContext
  );
  const [infoMissing, setInfoMissing] = useState(false);

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const isMissing = (stringArray: string[]) => {
    let missing = false;
    stringArray.forEach(inputValue => {
      if (!inputValue) {
        missing = true;
      }
    });
    return missing;
  };

  const validateEmail = (email: string) => {
    return email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
  };

  const validateInfo = () => {
    if (
      isMissing([
        customerInfo.name,
        customerInfo.phoneNumber,
        customerInfo.email
      ]) ||
      !validateEmail(customerInfo.email)
    ) {
      setInfoMissing(true);
    } else {
      setInfoMissing(false);
      goNext();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const { value } = target;
    const { name } = target;

    // urrr
    // eslint-disable-next-line
    (customerInfo as any)[name] = value;
    updateCustomerInfo(customerInfo);
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Customer.Customer')}
      buttonLabel={t('Continue')}
      buttonIcon="BackArrow"
      onIconButtonClicked={goBack}
      onButtonClicked={validateInfo}
    >
      <Wrapper>
        <InputWrapper>
          <div id="component-container" />
          <InputItem>
            <Label error={infoMissing && customerInfo.name === ''}>
              {t('Customer.Name')}
            </Label>
            <Input
              type="text"
              name="name"
              defaultValue={customerInfo.name}
              onChange={handleChange}
              error={infoMissing && customerInfo.name === ''}
            />
            {infoMissing && customerInfo.name === '' && (
              <Error>{t('Customer.NameMissing')}</Error>
            )}
          </InputItem>
          <InputItem>
            <Label error={infoMissing && customerInfo.phoneNumber === ''}>
              {t('Customer.Phone')}
            </Label>
            <Input
              type="text"
              name="phoneNumber"
              defaultValue={customerInfo.phoneNumber}
              onChange={handleChange}
              error={infoMissing && customerInfo.phoneNumber === ''}
            />
            {infoMissing && customerInfo.phoneNumber === '' && (
              <Error>{t('Customer.PhoneMissing')}</Error>
            )}
          </InputItem>
          <InputItem>
            <Label
              error={
                (infoMissing && customerInfo.email === '') ||
                (infoMissing && !validateEmail(customerInfo.email))
              }
            >
              {t('Customer.Email')}
            </Label>
            <Input
              type="text"
              name="email"
              defaultValue={customerInfo.email}
              onChange={handleChange}
              error={
                (infoMissing && customerInfo.email === '') ||
                (infoMissing && !validateEmail(customerInfo.email))
              }
            />
            {((infoMissing && customerInfo.email === '') ||
              (infoMissing && !validateEmail(customerInfo.email))) && (
              <Error>{t('Customer.EmailMissing')}</Error>
            )}
          </InputItem>
          <InputItem>
            <Label>{t('Customer.Info')}</Label>
            <Input
              type="text"
              name="extraInfo"
              defaultValue={customerInfo.extraInfo}
              onChange={handleChange}
            />
          </InputItem>
        </InputWrapper>
      </Wrapper>
    </BasketModalContent>
  );
};

export default UserInfo;
