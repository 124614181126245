import { useState } from 'react';
import { CustomerStateType } from '../types/global-state-data.type';
import {
  CustomerInfo,
  CustomerAddress,
  DeliverySelection,
  Customer
} from '../types';

const emptyCustomerInfo: CustomerInfo = {
  name: '',
  phoneNumber: '',
  email: '',
  extraInfo: ''
};

const emptyCustomerAddress: CustomerAddress = {
  address: '',
  city: '',
  postalCode: '',
  extraDeliveryInfo: ''
};

const getInitialCustomerInfo = (): CustomerInfo => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const custInfo = localStorage.getItem('CustomerInfo');
    if (custInfo) {
      return JSON.parse(custInfo);
    }
  }
  return emptyCustomerInfo;
};

const getInitialCustomerAddress = (): CustomerAddress => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const custAddress = localStorage.getItem('CustomerAddress');
    if (custAddress) {
      return JSON.parse(custAddress);
    }
  }
  return emptyCustomerAddress;
};

const useCustomerState = (): CustomerStateType => {
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>(
    getInitialCustomerInfo()
  );
  const [customerAddress, setCustomerAddress] = useState<CustomerAddress>(
    getInitialCustomerAddress()
  );
  const [deliveryOption, setDeliveryOption] = useState<string>(
    DeliverySelection.Pickup
  );
  const updateCustomerInfo = (cInfo: CustomerInfo) => {
    const updatedCustomerInfo = {
      name: cInfo.name,
      phoneNumber: cInfo.phoneNumber,
      email: cInfo.email,
      extraInfo: cInfo.extraInfo
    };
    setCustomerInfo(updatedCustomerInfo);
    localStorage.setItem('CustomerInfo', JSON.stringify(updatedCustomerInfo));
  };

  const updateCustomerAddress = (cAddress: CustomerAddress) => {
    const updatedCustomerAddress = {
      address: cAddress.address,
      city: cAddress.city,
      postalCode: cAddress.postalCode,
      extraDeliveryInfo: cAddress.extraDeliveryInfo
    };
    setCustomerAddress(updatedCustomerAddress);
    localStorage.setItem(
      'CustomerAddress',
      JSON.stringify(updatedCustomerAddress)
    );
  };

  const clearCustomer = () => {
    setCustomerInfo(emptyCustomerInfo);
    setCustomerAddress(emptyCustomerAddress);
    localStorage.setItem('CustomerInfo', JSON.stringify(emptyCustomerInfo));
    localStorage.setItem(
      'CustomerAddress',
      JSON.stringify(emptyCustomerAddress)
    );
  };

  const getCustomerInfo = (): Customer => ({
    name: customerInfo.name,
    phoneNumber: customerInfo.phoneNumber,
    email: customerInfo.email,
    address: customerAddress.address,
    city: customerAddress.city,
    postalCode: customerAddress.postalCode,
    extraInfo: customerInfo.extraInfo,
    extraDeliveryInfo:
      deliveryOption === DeliverySelection.Delivery
        ? customerAddress.extraDeliveryInfo
        : ''
  });

  return {
    customerInfo,
    customerAddress,
    updateCustomerInfo,
    updateCustomerAddress,
    clearCustomer,
    deliveryOption,
    setDeliveryOption,
    getCustomerInfo
  };
};

export default useCustomerState;
