import React, { useState, useContext } from 'react';
import {
  Loader,
  PaymentButton,
  BasketModalContent,
  PriceSummaryList
} from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';

import * as S from './OBPayPayment.styles';
import { DisableScreenClick } from '../../Payment.styles';
import { PaymentProps, PaymentMethod } from './OBPayPayment.types';
import { CardPayment, GooglePayment, ApplePayment } from './PaymentMethods';
import { postSale } from '../../../../../../../services/payment.service';
import GlobalContext from '../../../../../../../state/GlobalContext';
import {
  PaymentStatus,
  DigitalWalletResponseInfo
} from '../../../../../../../types';
import { useSelectedTimeSlot } from '../../../../../../../state/useSelectedTimeSlot';

const OBPayPayment = ({ goNext, goBack, totalAmount }: PaymentProps) => {
  const {
    getCustomerInfo,
    basket,
    deliveryOption,
    visualProfileColors,
    currencyCode,
    paymentProvider
  } = useContext(GlobalContext);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { selectedTimeSlot } = useSelectedTimeSlot();

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const priceSummary = [
    {
      id: 0,
      label: t('Basket.TotalToPay'),
      value: totalAmount || 0,
      bold: true
    }
  ];

  const finalizeSale = async (paymentResponse: {
    globalPayResponse?: any;
    digitalWallet?: DigitalWalletResponseInfo;
  }) => {
    setIsLoading(true);
    const response = await postSale(
      getCustomerInfo(),
      deliveryOption,
      basket.basketItems,
      basket.externalUid,
      selectedTimeSlot,
      paymentResponse
    );
    if (response.status < 400) {
      goNext({
        paymentStatus: PaymentStatus.Success,
        salesResponse: response.data
      });
    } else {
      goNext({ paymentStatus: PaymentStatus.Failed });
    }
  };

  const renderPaymentMethodContent = () => {
    switch (paymentMethod) {
      case PaymentMethod.Card:
        return (
          <CardPayment
            goNext={goNext}
            finalizeSale={finalizeSale}
            totalAmount={totalAmount}
          />
        );
      case PaymentMethod.ApplePay:
      case PaymentMethod.GooglePay:
        return (
          <>
            <S.Title>{t('Basket.PaymentInProgress')}</S.Title>
            <Loader width={200} color="black" />
          </>
        );
      default:
        return <></>;
    }
  };

  const handleGoBack = () => {
    if (paymentMethod === PaymentMethod.Card) {
      setPaymentMethod(undefined);
    } else {
      goBack();
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <DisableScreenClick />
          <S.Title>{t('Basket.PaymentInProgress')}</S.Title>
          <Loader width={200} color="black" />
        </>
      );
    }

    if (!paymentMethod) {
      return (
        <>
          <S.Title>{t('Basket.HowWouldYouLikeToFinishYourPayment')}</S.Title>
          <S.ButtonWrapper>
            <PaymentButton
              icon="Card"
              label="Card"
              theme="Dark"
              onClicked={() => setPaymentMethod(PaymentMethod.Card)}
            />
            {paymentProvider?.information?.enableApplePay && (
              <ApplePayment
                goNext={goNext}
                goBack={() => setPaymentMethod(undefined)}
                finalizeSale={finalizeSale}
                totalAmount={totalAmount}
                handlePaymentMethodChosen={() =>
                  setPaymentMethod(PaymentMethod.ApplePay)
                }
              />
            )}
            {paymentProvider?.information?.enableGooglePay && (
              <GooglePayment
                goNext={goNext}
                goBack={() => setPaymentMethod(undefined)}
                finalizeSale={finalizeSale}
                totalAmount={totalAmount}
                handlePaymentMethodChosen={() =>
                  setPaymentMethod(PaymentMethod.GooglePay)
                }
              />
            )}
          </S.ButtonWrapper>
        </>
      );
    }

    return <>{renderPaymentMethodContent()}</>;
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Basket.Payment')}
      buttonIcon="BackArrow"
      onIconButtonClicked={handleGoBack}
    >
      <S.Wrapper>
        <PriceSummaryList
          listItems={priceSummary}
          currencyCode={currencyCode}
        />
        <S.ContentWrapper>{renderContent()}</S.ContentWrapper>
      </S.Wrapper>
    </BasketModalContent>
  );
};

export default OBPayPayment;
