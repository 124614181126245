import { AxiosResponse } from 'axios';
import api from '../utils/api';

export const getApplePaymentSession = async (
  validationUrl: string,
  domain: string
): Promise<AxiosResponse<any>> => {
  return api.post('ApplePay/paymentSession', {
    validationUrl,
    domain
  });
};
