import styled from 'styled-components';

export const PaymentExtensionIframe = styled.iframe`
  width: 100%;
  border: none;
`;

export const IFrameContainer = styled.div`
  display: ${props => (props.hidden ? 'none' : 'block')};
`;

export const Wrapper = styled.div`
  color: black;
  font-size: 1rem;
  padding: 0px;
`;

export const TopMargin = styled.div`
  margin-top: 40%;
`;
