import styled, { css } from 'styled-components';

export const ModalBackground = styled.div<{ showModal: boolean }>`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 150;
  cursor: pointer;

  justify-content: center;
  align-items: center;
  display: flex;

  ${({ showModal }) =>
    !showModal &&
    css`
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.2s, opacity 0.2s linear;
    `}

  ${({ showModal }) =>
    showModal &&
    css`
      visibility: visible;
      opacity: ;
      transition: visibility 0.2s 0s, opacity 0.2s linear;
    `}
`;

export const Modal = styled.img`
  max-width: 90vw;
  max-height: 90vh;
`;
