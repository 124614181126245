import React, { useContext, useState, useEffect } from 'react';
import {
  Loader,
  PaymentButton,
  BasketModalContent,
  PriceSummaryList
} from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';

import * as S from './ValitorPayment.styles';
import { DisableScreenClick } from '../../Payment.styles';
import { PaymentProps } from './ValitorPayment.types';
import {
  postSale,
  getValitorSignature
} from '../../../../../../../services/payment.service';
import loadScript from '../../../../../../../utils/loadScript';
import GlobalContext from '../../../../../../../state/GlobalContext';
import {
  PaymentStatus,
  ValitorSignatureResponse
} from '../../../../../../../types';
import { useSelectedTimeSlot } from '../../../../../../../state/useSelectedTimeSlot';

declare const window: any;

const ValitorPayment = ({ goNext, goBack, totalAmount }: PaymentProps) => {
  const {
    paymentProvider,
    currencyCode,
    basket,
    getCustomerInfo,
    deliveryOption,
    visualProfileColors
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const { selectedTimeSlot } = useSelectedTimeSlot();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPostingSale, setIsPostingSale] = useState<boolean>(false);
  const [valitorLoaded] = loadScript(paymentProvider?.information?.url || '');
  const [isWaiting, setFetching] = useState<boolean>(true);
  const [
    valitorSignature,
    setValitorSignature
  ] = useState<ValitorSignatureResponse>();

  // making sure ISK don't have decimals, that will break valitor integration for ISK.
  // todo: Move elsewhere or delete
  const totalPrice: number =
    currencyCode === 'ISK' ? Number(totalAmount?.toFixed(0)) : totalAmount || 0;

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const priceSummary = [
    {
      id: 0,
      label: t('Basket.TotalToPay'),
      value: totalAmount || 0,
      bold: true
    }
  ];

  const getValitorResponseData = async (e: any) => {
    setIsLoading(true);
    setIsPostingSale(true);
    const formData: any = new FormData(e.target);
    const valitorResponse = JSON.parse(formData.get('response'));

    e.preventDefault();
    const salesResponse = await postSale(
      getCustomerInfo(),
      deliveryOption,
      basket.basketItems,
      basket.externalUid,
      selectedTimeSlot,
      {
        valitorPayResponse: {
          paymentMethod: 4,
          cardType: valitorResponse.CardType,
          authorizationNumber: valitorResponse.AuthorizationNumber,
          transactionNumber: valitorResponse.TransactionNumber,
          agreementNumber: valitorResponse.AgreementNumber
        }
      }
    );

    if (salesResponse.status === 200) {
      goNext({
        paymentStatus: PaymentStatus.Success,
        salesResponse: salesResponse.data
      });
    } else {
      goNext({ paymentStatus: PaymentStatus.Failed });
    }
  };

  // countering a bug in the Valitor iFrame where it's not ready to take input
  setTimeout(() => {
    setFetching(false);
  }, 1000);

  useEffect(() => {
    if (valitorLoaded) {
      (async () => {
        // code to fetch valitor merchant moved to global
        // to battle time sensitivity of the valitor iframe
        window.valitor.checkout.init({
          key: paymentProvider?.information?.key,
          language: 'en',
          merchant: paymentProvider?.information?.name,
          currency: currencyCode
        });

        const response = await getValitorSignature(totalPrice);
        setValitorSignature(response?.data);

        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })();
    }
  }, [valitorLoaded]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          {isPostingSale && <DisableScreenClick />}
          <S.Title>{t('Basket.PaymentInProgress')}</S.Title>
          <Loader width={200} color="black" />
        </>
      );
    }

    return (
      <>
        <S.Title>{t('Basket.PaymentMethod')}</S.Title>
        <S.ButtonWrapper>
          <PaymentButton
            label={t('Basket.Card')}
            icon="Card"
            onClicked={() =>
              window.valitor.checkout.open({
                amount: totalPrice,
                merchantReferenceId: valitorSignature?.merchantReferenceId,
                digitalSignature: valitorSignature?.signature
              })
            }
            loading={isWaiting}
          />
        </S.ButtonWrapper>
      </>
    );
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Basket.Payment')}
      buttonIcon="BackArrow"
      onIconButtonClicked={() => goBack()}
    >
      <S.Wrapper>
        <PriceSummaryList
          listItems={priceSummary}
          currencyCode={currencyCode}
        />
        <form id="valitorCheckout" onSubmit={getValitorResponseData} />
        <S.ContentWrapper> {renderContent()}</S.ContentWrapper>
      </S.Wrapper>
    </BasketModalContent>
  );
};

export default ValitorPayment;
