import React, { useState, useContext, createContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import {
  Layout as LayoutComponent,
  Toaster
} from '@k3imagine/self-serve-components';
import GlobalContext from '../../state/GlobalContext';
import { BasketModalWizard, WizardSteps } from '../BasketModal';
import { useDebounce } from '../../state/useDebounce';
import { GridItemProps, Breadcrumb, OrderCustomization } from '../../types';
import { ProductDetailModal, InformationModal } from '..';
import BasketModal from '../BasketModal/BasketModal';

export const LayoutContext = createContext<{
  search: string;
  setSearch: Function;
  showProductDetailModal: boolean;
  productDetailModalInfo?: {
    product: GridItemProps & { customization?: OrderCustomization };
    isNew: boolean;
  };
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: Function;
  setShowProductDetailModal: Function;
  setProductDetailModalInfo: Function;
  setShowBasketModal: Function;
}>({
  search: '',
  breadcrumbs: [],
  setBreadcrumbs: () => {},
  setSearch: () => {},
  showProductDetailModal: true,
  setShowProductDetailModal: () => {},
  setProductDetailModalInfo: () => {},
  setShowBasketModal: () => {}
});

export default ({
  children,
  location
}: {
  children: any;
  location: Location;
}) => {
  const [showBasketModal, setShowBasketModal] = useState<boolean>(false);
  const [showProductDetailModal, setShowProductDetailModal] = useState(false);
  const [productDetailModalInfo, setProductDetailModalInfo] = useState();
  const [showInformationModal, setShowInformationModal] = useState<boolean>(
    false
  );
  const [search, setSearch] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const {
    basket,
    imageUrl,
    defaultLocale,
    company,
    rootComposerGroup,
    visualProfileColors,
    config
  } = useContext(GlobalContext);

  const { t } = useTranslation();

  const styles = {
    basketButton: {
      background: visualProfileColors?.button?.primary?.background
    }
  };

  const hasShopPolicies =
    config?.faqEnabled ||
    config?.termsAndCondEnabled ||
    config?.deliveryPolicyEnabled ||
    config?.returnsPolicyEnabled;

  // catches the case when a user goes to /:company
  if (
    typeof window !== 'undefined' &&
    window &&
    !location.pathname.includes('groups')
  ) {
    navigate(`/${company}/groups/${rootComposerGroup?.groupUrlName}`);
  }

  useEffect(() => {
    // When the modal is shown, we want a fixed body so that the application does not scroll in the background
    document.body.style.overflow =
      showBasketModal || showProductDetailModal || showInformationModal
        ? 'hidden'
        : 'initial';
  }, [showBasketModal, showProductDetailModal, showInformationModal]);

  const breadcrumbsClicked = (breadcrumb: Breadcrumb) => {
    setSearch('');
    const breadcrumbIndex = breadcrumbs.indexOf(breadcrumb);
    const newBreadcrumbs = breadcrumbs.slice(0, breadcrumbIndex + 1);
    const groupPath = newBreadcrumbs.map(b => b.value.groupUrlName).join('/');
    setBreadcrumbs(newBreadcrumbs);
    navigate(`/${company}/groups/${groupPath}`);
  };

  const handleShowModal = (showModalValue: boolean) => {
    setShowBasketModal(showModalValue);
  };

  const handleSearch = (query: string) => {
    if (query && query.length > 1) {
      setSearch(query);
    }
  };

  const onShowInformationModal = () => {
    setShowInformationModal(true);
  };

  return (
    <>
      <LayoutContext.Provider
        value={{
          search: useDebounce(300, search),
          setSearch,
          showProductDetailModal,
          setShowProductDetailModal,
          productDetailModalInfo,
          setProductDetailModalInfo,
          setShowBasketModal,
          breadcrumbs,
          setBreadcrumbs
        }}
      >
        <LayoutComponent
          styles={styles}
          searchPlaceholder={t('Search')}
          locale={defaultLocale}
          searchValue={search}
          logoUrl={imageUrl}
          onLogoClicked={() => {
            setSearch('');
            navigate(`/${company}/groups/${rootComposerGroup?.groupUrlName}`);
          }}
          onSearchClicked={handleSearch}
          onInfoClicked={hasShopPolicies && onShowInformationModal}
          onBasketClicked={() => setShowBasketModal(true)}
          onBreadcrumbClicked={breadcrumbsClicked}
          basketQuantity={basket.totalQuantity}
          breadcrumbs={breadcrumbs}
        >
          {children}
        </LayoutComponent>
        <BasketModal
          showModal={showBasketModal}
          handleShowModal={handleShowModal}
        />
        <ProductDetailModal />
        <InformationModal
          showModal={showInformationModal}
          onModalClosed={() => setShowInformationModal(false)}
        />
        <Toaster />
      </LayoutContext.Provider>
    </>
  );
};
