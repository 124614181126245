import { createContext } from 'react';
import { GlobalContextType } from '../types/global-state-data.type';

const GlobalContext = createContext<GlobalContextType>({
  basket: { totalPrice: 0, totalQuantity: 0, basketItems: [], externalUid: '' },
  composerTiles: [],
  currencyCode: '',
  imageUrl: '',
  loading: false,
  error: false,
  defaultLocale: 'en-gb',
  customerInfo: { name: '', phoneNumber: '', email: '', extraInfo: '' },
  customerAddress: {
    address: '',
    city: '',
    postalCode: '',
    extraDeliveryInfo: ''
  },
  deliveryOption: '',
  freeDeliveryThreshold: 0,
  deliveryAvailable: true,
  pickupAvailable: true,
  isTimeSlotsEnabled: false,
  company: '',
  updateCustomerInfo: () => {},
  updateCustomerAddress: () => {},
  clearCustomer: () => {},
  setDeliveryOption: () => {},
  getBasketItems: () => {},
  addBasketItem: () => {},
  updateBasketItem: () => {},
  removeBasketItem: () => {},
  clearBasket: () => {},
  updateBasket: () => {},
  getComposerTiles: () => {},
  getCustomerInfo: () => {},
  basketLoading: false
});

export default GlobalContext;
