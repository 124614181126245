import React, { useContext } from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import { ErrorScreen, Grid, Layout, StartScreen } from '../components';
import '../locales/i18n';
import useGlobalState from '../state/useGlobalState';
import useBasketState from '../state/useBasketState';
import useCustomerState from '../state/useCustomerState';
import GlobalContext from '../state/GlobalContext';

type RouteProps = {
  component: React.ComponentType<any>;
  children?: React.ReactNode;
} & RouteComponentProps;

const Route = ({
  component: Component,
  path,
  children,
  ...rest
}: RouteProps) => {
  return <Component {...rest}>{children}</Component>;
};

// Working pathmaps for Mock API
// melabudin
// aldrei

const App = () => {
  const { visualProfileFonts } = useContext(GlobalContext);

  const GlobalStyles = createGlobalStyle`
    @import url(${visualProfileFonts?.stylesheetUrl});
    * {
      font-family: ${
        visualProfileFonts?.name || 'Source Sans Pro'
      }, 'Source Sans Pro';

      touch-action: manipulation;
    }
    body {
     margin: 0px;
    }
  `;

  return (
    <>
      <Helmet>
        <title>K3 POP</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <GlobalStyles />
      <Router>
        <Route path="/" component={StartScreen}>
          <Route path="/:company" component={Layout}>
            <Route path="/groups/*" component={Grid} />
          </Route>
        </Route>
        <Route path="/error" component={ErrorScreen} />
      </Router>
    </>
  );
};

const Index = () => {
  const globalState = {
    ...useGlobalState(),
    ...useBasketState(),
    ...useCustomerState()
  };

  return (
    <GlobalContext.Provider value={globalState}>
      <App />
    </GlobalContext.Provider>
  );
};

export default Index;
