import styled from 'styled-components';

export const GlobalPayIframe = styled.iframe`
  width: 100%;
  border: none;
  background-color: #f9f9f9;
  margin-top: 15px;
  display: none;
`;

export const GlobalPayButton = styled.button`
  display: none;
`;

export const Wrapper = styled.div`
  text-align: center;
  color: black;
  font-size: 1rem;
  margin-top: 10%;
`;

export const ContentWrapper = styled.div`
  color: black;
  font-size: 1rem;
  padding: 40px;
`;

export const TopMargin = styled.div`
  margin-top: 40%;
`;

export const ButtonWrapper = styled.div`
  max-width: 240px;
  margin: 0 auto;
  button {
    margin: 10px 0;
  }
`;

export const Title = styled.div`
  margin-bottom: 60px;
  font-weight: 200;
`;
