import React, { createContext, useContext, useState } from 'react';
import { TimeSlot } from '../types/time-slot.type';

export type SelectedTimeSlotState = {
  selectedTimeSlot: TimeSlot | null;
  setSelectedTimeSlot: (timeSlot: TimeSlot) => void;
  clearSelectedTimeSlot: () => void;
};

const SelectedTimeSlotContext = createContext(null);

const useSelectedTimeSlot = () => {
  const context = useContext(SelectedTimeSlotContext);

  if (!context) {
    throw new Error(
      'useSelectedTimeSlot must be used within a SelectedTimeSlotProvider'
    );
  }
  return context;
};

const SelectedTimeSlotProvider = (props: any) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot | null>(
    null
  );

  const state: SelectedTimeSlotState = {
    selectedTimeSlot,
    setSelectedTimeSlot: (timeSlot: TimeSlot) => {
      setSelectedTimeSlot(timeSlot);
    },
    clearSelectedTimeSlot: () => {
      setSelectedTimeSlot(null);
    }
  };

  return <SelectedTimeSlotContext.Provider value={state} {...props} />;
};

export { SelectedTimeSlotProvider, useSelectedTimeSlot };
