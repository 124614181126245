import { BasketItem, BasketType } from '../types';
import { mapBasketToFrontEnd } from '../utils/basket';
import api from '../utils/api';

interface BasketTypeWithData {
  status: number;
  data: BasketType;
}

export const createBasket = async (): Promise<BasketType> => {
  try {
    const { data } = await api.post('/basket/create', []);
    return mapBasketToFrontEnd(data);
  } catch (e) {
    return e.response;
  }
};

export const getBasketById = async (
  basketId: string
): Promise<BasketTypeWithData> => {
  const { data, status } = await api.get(`/basket/${basketId}`);

  return { data: mapBasketToFrontEnd(data), status };
};

export const patchAddItemsByBasketId = async (
  basketId: string,
  basketItems: BasketItem
): Promise<BasketType> => {
  try {
    const { data } = await api.patch(`/Basket/${basketId}/add/items`, [
      basketItems
    ]);

    return mapBasketToFrontEnd(data);
  } catch (e) {
    return e.response;
  }
};

export const patchUpdateItemsByBasketId = async (
  basketId: string,
  basketItems: BasketItem
): Promise<BasketType> => {
  try {
    const { data } = await api.patch(`/Basket/${basketId}/update/items`, [
      basketItems
    ]);

    return mapBasketToFrontEnd(data);
  } catch (e) {
    return e.response;
  }
};

export const patchRemoveItemsById = async (
  basketId: string,
  basketItemId: number
): Promise<BasketType> => {
  try {
    const { data } = await api.patch(
      `/Basket/${basketId}/remove/item/basketItemId/${basketItemId}`
    );
    return mapBasketToFrontEnd(data);
  } catch (e) {
    return e.response;
  }
};

export const patchRemoveAllItemsByBasketId = async (
  basketId: string
): Promise<BasketType> => {
  try {
    const { data } = await api.patch(`/Basket/${basketId}/removeAllItems`);

    return data;
  } catch (e) {
    return e.response;
  }
};
