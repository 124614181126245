import { AxiosResponse } from 'axios';
import api from '../utils/api';

export const getAvailableTimeSlotsByShop = async (): Promise<
  AxiosResponse<any>
> => {
  try {
    const params = new URLSearchParams();
    const todayIso = new Date().toISOString();
    params.append('dateTime', todayIso);

    return await api.get('/timeslots/available', { params });
  } catch (e) {
    return e.response;
  }
};
