import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, BasketModalContent } from '@k3imagine/self-serve-components';

import * as S from './BasketValidation.styles';
import GlobalContext from '../../../../../state/GlobalContext';
import { getPaymentBreakdown } from '../../../../../services/payment.service';
import { PaymentStatus, BasketItem, SaleItem } from '../../../../../types';
import NoBasketItemsAvailable from './NoBasketItemsAvailable/NoBasketItemsAvailable';
import SomeBasketItemsNotAvailable from './SomeBasketItemsNotAvailable/SomeBasketItemsNotAvailable';

type BasketValidationProps = {
  goNext: Function;
  goBack: Function;
  goToStart: Function;
  goToPaymentStatus: Function;
};

const BasketValidation = ({
  goNext,
  goBack,
  goToStart,
  goToPaymentStatus
}: BasketValidationProps) => {
  const [availableBasketItems, setAvailableBasketItems] = useState<
    BasketItem[]
  >([]);
  const [unavailableBasketItems, setUnavailableBasketItems] = useState<
    BasketItem[]
  >([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {
    basket,
    visualProfileColors,
    deliveryItemId,
    getComposerTiles,
    updateBasket
  } = useContext(GlobalContext);
  const { t } = useTranslation();

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const goBackAfterBasketValidation = () => {
    getComposerTiles();
    goBack();
  };

  const mapSaleItemsToBasketItems = (saleItems: SaleItem[]) => {
    let basketItems: BasketItem[] = [];
    saleItems.forEach((i: SaleItem) => {
      const foundBasketItem = basket.basketItems.find(
        b => b.id === i.localStorageBasketItemIdentifier
      );
      if (foundBasketItem) {
        basketItems = [
          ...basketItems,
          {
            ...foundBasketItem,
            quantity: i.quantity,
            price: foundBasketItem.price
          }
        ];
      }
    });

    return basketItems;
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const response = await getPaymentBreakdown(
        basket.basketItems,
        basket.externalUid
      );
      if (response.status >= 400) {
        goToPaymentStatus({ paymentStatus: PaymentStatus.Failed });
        return;
      }

      // if there are no unavailable basket items, we go straight to next step
      if (
        !response?.data?.unavailableBasketItems ||
        response?.data?.unavailableBasketItems.length <= 0
      ) {
        goNext(response?.data?.totalAmount || 0);
        return;
      }

      setIsLoading(false);
      setTotalAmount(response?.data?.totalAmount);
      // If only delivery item is available, we treat it as no available items
      if (
        response?.data?.availableBasketItems &&
        response?.data?.availableBasketItems.length === 1 &&
        response?.data?.availableBasketItems[0].referenceId === deliveryItemId
      ) {
        setAvailableBasketItems([]);
        updateBasket([]);
      } else {
        const mappedAvailableBasketItems = mapSaleItemsToBasketItems(
          response?.data?.availableBasketItems || []
        );
        setAvailableBasketItems(mappedAvailableBasketItems);
        updateBasket(mappedAvailableBasketItems);
      }
      setUnavailableBasketItems(
        mapSaleItemsToBasketItems(response?.data?.unavailableBasketItems || [])
      );
    })();
  }, []);

  const renderContent = () => {
    if (isLoading) {
      return (
        <S.LoaderWrapper>
          <Loader width={200} color="black" />
        </S.LoaderWrapper>
      );
    }

    // if no available items
    if (!availableBasketItems || availableBasketItems.length <= 0) {
      return (
        <NoBasketItemsAvailable
          unavailableBasketItems={unavailableBasketItems}
          goToStart={goToStart}
        />
      );
    }

    return (
      <SomeBasketItemsNotAvailable
        totalAmount={totalAmount}
        availableBasketItems={availableBasketItems}
        unavailableBasketItems={unavailableBasketItems}
      />
    );
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Basket.Payment')}
      onIconButtonClicked={goBackAfterBasketValidation}
      leftButtonIcon="BackArrow"
      buttonLabel={t('BasketValidation.ContinueToPayment')}
      onButtonClicked={() => goNext(totalAmount)}
      buttonDisabled={availableBasketItems.length <= 0}
    >
      <S.Wrapper>{renderContent()}</S.Wrapper>
    </BasketModalContent>
  );
};

export default BasketValidation;
