import styled from 'styled-components';

export const GlobalPayIframe = styled.iframe`
  width: 100%;
  border: none;
  background-color: #f9f9f9;
  margin-top: 15px;
  display: none;
`;

export const GlobalPayButton = styled.button`
  display: none;
`;

export const TopMargin = styled.div`
  margin-top: 40%;
`;

export const IFrameContainer = styled.div`
  display: ${props => (props.hidden ? 'none' : 'block')};
`;
