import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  color: black;
  font-size: 1rem;
  margin-top: 10%;
`;

export const Title = styled.div`
  margin-bottom: 60px;
  font-weight: 200;
`;

export const ErrorTitle = styled.div`
  margin: 60px 0;
  font-weight: 300;
  font-size: 30px;
`;

export const ErrorSubTitle = styled.div`
  font-weight: 200;
`;

export const DisableScreenClick = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: #b2b2b2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 110;
`;
