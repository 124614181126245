import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  PaymentButton,
  Select,
  BasketModalContent,
  Loader
} from '@k3imagine/self-serve-components';

import GlobalContext from '../../../../../state/GlobalContext';
import {
  useAvailableTimeSlots,
  AvailableTimeSlotState
} from '../../../../../state/useAvailableTimeSlots';
import {
  useSelectedTimeSlot,
  SelectedTimeSlotState
} from '../../../../../state/useSelectedTimeSlot';
import {
  compareTimes,
  findNearestTimeSlot,
  formatTimeSlot,
  getCurrentTime
} from '../../../../../utils/time-slot-utils';
import { TimeSlot, TimeSlotOption } from '../../../../../types/time-slot.type';
import { Time } from '../../../../../types/time.type';
import { PaymentStatus } from '../../../../../types';

type TimeSlotSelectionProps = {
  goNext: Function;
  goBack: Function;
  goToTransactionStep: (paymentStatus: PaymentStatus) => void;
};

const Wrapper = styled.div`
  text-align: center;
  color: black;
  font-size: 1rem;
  margin-left: 30px;
  margin-right: 30px;

  > * {
    margin-top: 30px;
  }
`;

const TextSeparator = styled.div`
  font-style: italic;
`;

const TimeSlotSelection = ({
  goBack,
  goNext,
  goToTransactionStep
}: TimeSlotSelectionProps) => {
  let relativeTimeSlots: TimeSlot[] = [];

  const { t } = useTranslation();
  const { visualProfileColors } = useContext(GlobalContext);

  const {
    status: availableTimeSlotStatus,
    availableTimeSlots
  }: AvailableTimeSlotState = useAvailableTimeSlots();

  const {
    selectedTimeSlot,
    setSelectedTimeSlot
  }: SelectedTimeSlotState = useSelectedTimeSlot();

  useEffect(() => {
    if (availableTimeSlotStatus === 'ERROR') {
      // If time slots fail to load for some reason, the checkout process is aborted.
      goToTransactionStep(PaymentStatus.TimeSlotsLoadFailure);
      return;
    }

    if (availableTimeSlotStatus === 'LOADED') {
      const nearestTimeSlot = findNearestTimeSlot(availableTimeSlots);
      if (nearestTimeSlot === undefined) {
        /**
         * If current time is after the start time of last time slot, then the location is closed
         * and the checkout process aborted.
         */
        goToTransactionStep(PaymentStatus.LocationClosed);
      }
    }
  }, [availableTimeSlotStatus]);

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const handleSelectNextTimeslot = () => {
    const nonFullTimeSlots = availableTimeSlots.filter((timeSlot: TimeSlot) => {
      return !timeSlot.isFull;
    });

    const nearestTimeSlot: TimeSlot | undefined = findNearestTimeSlot(
      nonFullTimeSlots
    );

    if (nearestTimeSlot) {
      setSelectedTimeSlot(nearestTimeSlot);
    }
  };

  const handleSelectTimeslot = (timeSlotOption: TimeSlotOption) => {
    const timeSlotSelected = relativeTimeSlots.find(
      (timeSlot: TimeSlot) => timeSlot.id === timeSlotOption.id
    );

    if (timeSlotSelected) {
      setSelectedTimeSlot(timeSlotSelected);
    }
  };

  const renderSelection = () => {
    // Only show relative time slots - not showing any slots with a start time earlier than "now".
    relativeTimeSlots = availableTimeSlots.filter((timeSlot: TimeSlot) => {
      const currentTime: Time = getCurrentTime();
      const timeCompare = compareTimes(timeSlot.start, currentTime);
      return timeCompare >= 0;
    });

    const timeSlotOptions = relativeTimeSlots.map((timeSlot: TimeSlot) => {
      const { id } = timeSlot;

      let label = formatTimeSlot(timeSlot);
      const value = `${timeSlot.start}-${timeSlot.end}`;
      let isDisabled = false;

      if (timeSlot.isFull) {
        label = `${label} (${t('TimeSlot.Full')})`;
        isDisabled = true;
      }

      return { id, label, value, isDisabled };
    });

    const timeSlotValue = selectedTimeSlot
      ? {
          label: `${selectedTimeSlot.start} - ${selectedTimeSlot.end}`,
          value: `${selectedTimeSlot.start}-${selectedTimeSlot.end}`
        }
      : null;

    return (
      <BasketModalContent
        styles={styles}
        title={t('TimeSlot.ChooseTimeSlot')}
        buttonLabel={t('Continue')}
        buttonIcon="BackArrow"
        onIconButtonClicked={() => goBack()}
        onButtonClicked={() => goNext()}
      >
        <Wrapper>
          <PaymentButton
            label={t('TimeSlot.NextAvailableSlot')}
            onClicked={() => handleSelectNextTimeslot()}
          />
          <TextSeparator>{t('TimeSlot.Or')}</TextSeparator>
          <Select
            value={timeSlotValue}
            options={timeSlotOptions}
            placeholder={t('TimeSlot.SelectSlot')}
            onChange={(timeSlotOption: TimeSlotOption) =>
              handleSelectTimeslot(timeSlotOption)
            }
          />
        </Wrapper>
      </BasketModalContent>
    );
  };

  const renderLoader = () => {
    return (
      <BasketModalContent
        styles={styles}
        buttonLabel={t('Continue')}
        buttonIcon="BackArrow"
      >
        <Wrapper>
          <Loader />
          <div>{`${t('TimeSlot.CheckingAvailability')}...`}</div>
        </Wrapper>
      </BasketModalContent>
    );
  };

  if (availableTimeSlotStatus === 'LOADING') {
    return renderLoader();
  }
  return renderSelection();
};

export default TimeSlotSelection;
