import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  color: black;
  font-size: 1rem;
  margin-top: 10%;
`;

export const ContentWrapper = styled.div`
  text-align: center;
  color: black;
  font-size: 1rem;
  padding: 40px;
`;

export const Title = styled.div`
  text-align: center;
  margin-bottom: 60px;
  font-weight: 200;
`;

export const ButtonWrapper = styled.div`
  > * {
    margin: 10px;
  }
`;
