import React from 'react';
import { BasketModalWizard } from '.';
import { SelectedTimeSlotProvider } from '../../state/useSelectedTimeSlot';
import { WizardSteps } from './components/WizardSteps';

type BasketModalProps = {
  showModal: boolean;
  handleShowModal: Function;
};

const BasketModal = ({ showModal, handleShowModal }: BasketModalProps) => {
  return (
    <SelectedTimeSlotProvider>
      <BasketModalWizard
        step={WizardSteps.BasketOverview}
        showModal={showModal}
        handleShowModal={handleShowModal}
      />
    </SelectedTimeSlotProvider>
  );
};

export default BasketModal;
