import { AxiosResponse } from 'axios';
import api from '../utils/api';
import {
  SaleItem,
  ValitorInformation,
  OBPayInformation,
  Customer,
  ValitorPaymentResponseInfo,
  SaleResponse,
  BasketItem,
  PaymentBreakdown,
  DigitalWalletResponseInfo,
  ValitorSignatureResponse
} from '../types';
import { TimeSlot } from '../types/time-slot.type';

const mapBasketItemsToSalesItems = (basketItem: BasketItem[]): SaleItem[] => {
  return basketItem.map(i => {
    let bundleSelections;
    let modifierSelections;
    if (i.customization?.modifierSelections) {
      modifierSelections = i.customization?.modifierSelections.map(m => ({
        modifierId: m.modifierId,
        quantity: m.quantity
      }));
    }
    if (i.customization?.bundleSelections) {
      bundleSelections = i.customization?.bundleSelections.map(m => ({
        referenceId: m.referenceId,
        bundleGroupId: m.bundleGroupId
      }));
    }

    return {
      localStorageBasketItemIdentifier: i.id,
      referenceId: i.referenceId,
      type: i.type,
      quantity: i.quantity,
      orderDetail: i.customization?.orderDetail,
      modifierSelections,
      bundleSelections
    };
  });
};

export const getValitorConfig = async (): Promise<
  AxiosResponse<ValitorInformation>
> => {
  return api.get('paymentprovider/valitor/merchant');
};

export const getValitorSignature = async (
  amount: number
): Promise<AxiosResponse<ValitorSignatureResponse>> => {
  return api.get('paymentprovider/valitor/signature', { params: { amount } });
};

export const getOBPayConfig = async (): Promise<
  AxiosResponse<OBPayInformation>
> => {
  return api.get('paymentprovider/globalpay/merchant');
};

export const getPaymentBreakdown = async (
  basketItems: BasketItem[],
  basketId: string
): Promise<AxiosResponse<PaymentBreakdown>> => {
  const salesItems = mapBasketItemsToSalesItems(basketItems);
  return api.post(`/payment/breakdown/${basketId}`, salesItems);
};

export const getGlobalPayHpp = async (
  requestData: any
): Promise<AxiosResponse<any>> => {
  return api.post('paymentprovider/globalpay/generatehpp', requestData);
};

export const postSale = async (
  customer: Customer,
  deliveryOption: string,
  basketItems: BasketItem[],
  basketId: string,
  selectedTimeSlot: TimeSlot | undefined,
  paymentResponse: {
    valitorPayResponse?: ValitorPaymentResponseInfo;
    globalPayResponse?: any;
    digitalWallet?: DigitalWalletResponseInfo;
  }
): Promise<AxiosResponse<SaleResponse>> => {
  const data = {
    basketId: basketId,
    name: customer.name,
    phoneNumber: customer.phoneNumber,
    email: customer.email,
    address: customer.address,
    city: customer.city,
    postalCode: customer.postalCode,
    extraInfo: customer.extraInfo,
    extraDeliveryInfo: customer.extraDeliveryInfo,
    deliveryOption,
    saleItems: mapBasketItemsToSalesItems(basketItems),
    orderTimeSlotStart: selectedTimeSlot?.start,
    orderTimeSlotEnd: selectedTimeSlot?.end,
    ...paymentResponse
  };
  return api.post('sales', data);
};
