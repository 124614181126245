import styled from 'styled-components';

export const Wrapper = styled.div`
  color: black;
  font-size: 1rem;
  padding: 0 10px;
`;

export const Title = styled.div`
  margin: 10px 0;
  font-weight: 200;
  font-size: 1.6rem;
  text-align: center;
`;

export const SubTitle = styled.div`
  margin: 20px 0;
  font-weight: 200;
  text-align: center;
`;

export const LoaderWrapper = styled.div`
  margin-top: 20%;
`;

export const NotAvailableOverlay = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ff4e61;
`;

export const BasketListWrapper = styled.div`
  position: relative;
  width: 80%;
  margin: auto;
`;
