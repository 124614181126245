import styled from 'styled-components';

export const ApplePayButton = styled.button`
  width: 100%;
  height: 52px;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plane;
  -apple-pay-button-style: black;
  border-radius: 10px;
`;
