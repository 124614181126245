const mapModifierSelections = (modifierSelections: any) => {
  const newMod = modifierSelections?.map((mod: any) => ({
    ...mod,
    referenceId: mod?.modifierId,
    label: mod?.displayName
  }));
  return newMod;
};

const mapBundleSelections = (bundleSelections: any) => {
  let newBundle = bundleSelections?.map((selection: any) => ({
    ...selection,
    referenceId: selection?.itemId,
    label: selection?.displayName
  }));
  newBundle = newBundle?.filter((bundle: any) => bundle.bundleGroupId !== 0);
  return newBundle;
};

export const mapBasketToFrontEnd = (basket: any) => {
  const newBasket = { ...basket };
  newBasket.basketItems = newBasket?.basketItems?.map(
    ({ orderDetail, bundleSelections, modifierSelections, ...rest }: any) => {
      const newMod = mapModifierSelections(modifierSelections);
      const newBundle = mapBundleSelections(bundleSelections);

      const price =
        rest.price && rest.quantity ? rest.price / rest.quantity : rest.price;

      return {
        ...rest,
        price,
        customization: {
          orderDetail,
          bundleSelections: newBundle,
          modifierSelections: newMod
        }
      };
    }
  );
  return newBasket;
};
