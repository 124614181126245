export enum WizardSteps {
  BasketOverview = 0,
  PickupOrDelivery,
  TimeslotSelection,
  UserInfo,
  UserAddress,
  BasketValidation,
  Payment,
  PaymentStatus
}
