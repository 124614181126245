import { useEffect, useState } from 'react';
import {
  createBasket,
  getBasketById,
  patchRemoveAllItemsByBasketId,
  patchUpdateItemsByBasketId,
  patchRemoveItemsById,
  patchAddItemsByBasketId
} from '../services/basket.service';
import {
  BasketType,
  BasketItem,
  GridItemProps,
  GridItemType,
  OrderCustomization
} from '../types/basket.type';
import { BasketStateType } from '../types/global-state-data.type';
import api from '../utils/api';
import {
  getNamedLocalStorage,
  setNamedLocalStorage
} from '../utils/namedLocalStorage';

const emptyBasket: BasketType = {
  totalPrice: 0,
  totalQuantity: 0,
  basketItems: [],
  externalUid: ''
};

const calculateTotal = (
  basketItems: BasketItem[]
): { totalPrice: number; totalQuantity: number } => {
  let totalPrice = 0;
  let totalQuantity = 0;
  basketItems.forEach(item => {
    if (item.quantity > 0) {
      let itemPrice = item.price || 0;

      totalPrice += itemPrice * item.quantity;
      totalQuantity += item.quantity;
    }
  });
  return { totalPrice, totalQuantity };
};

const createNewBasket = async (): Promise<BasketType> => {
  const companyName: string = window.location.href.split('/')[3];
  const pathMap = await (await api.get(`pathmap/${companyName}`))?.data;
  let result: BasketType;
  result = emptyBasket;
  if (pathMap && pathMap.tenantId && pathMap.shopId) {
    sessionStorage.setItem('tenantId', pathMap.tenantId.toString());
    sessionStorage.setItem('shopId', pathMap.shopId.toString());
    result = await createBasket();
    return result;
  }
  return result;
};

const useBasketState = (): BasketStateType => {
  const [basketLoading, setBasketLoading] = useState<boolean>(true);
  const [basket, setBasket] = useState<BasketType>(emptyBasket);
  const [basketId, setBasketId] = useState<string>('');

  const getInitialBasket = async (): Promise<void> => {
    const basketJson = getNamedLocalStorage('basket');
    const bask = basketJson ? JSON.parse(basketJson) : {};
    let result;
    if (bask.externalUid) {
      const { data } = await getBasketById(bask.externalUid);
      result = data;
      if (result.completedTime != null) {
        result = await createNewBasket();
      }
    } else {
      result = await createNewBasket();
    }
    if (result) {
      setBasketId(result.externalUid as string);
      setNamedLocalStorage('basket', JSON.stringify(result));
      setBasket(result);
      setBasketLoading(false);
    }
  };

  const getBasketItems = (
    referenceId?: number,
    type?: GridItemType
  ): BasketItem[] => {
    return basket.basketItems.filter(
      (basketItem: BasketItem) =>
        basketItem.referenceId === referenceId && basketItem.type === type
    );
  };

  const updateBasket = async (updatedBasketItems: BasketType) => {
    const { totalPrice, totalQuantity } = calculateTotal(
      updatedBasketItems.basketItems
    );

    const updatedBasket = {
      externalUid: basket.externalUid,
      basketItems: updatedBasketItems.basketItems,
      totalPrice,
      totalQuantity
    };

    setBasket(updatedBasket);
    setNamedLocalStorage('basket', JSON.stringify(updatedBasket));
  };

  const updateBasketItem = async (
    id: number,
    quantity: number,
    customization: OrderCustomization = {}
  ) => {
    const basketItemToUpdate = basket.basketItems.find(
      item => item.id === id
    ) as BasketItem;
    const newBasketItem = {
      ...basketItemToUpdate,
      bundleSelections: customization.bundleSelections,
      modifierSelections: customization.modifierSelections,
      orderDetail: customization.orderDetail,
      quantity
    };
    setBasketLoading(true);
    const newBasket = await patchUpdateItemsByBasketId(basketId, newBasketItem);

    updateBasket(newBasket);
    setBasketLoading(false);
  };

  const addBasketItem = async (
    newItem: GridItemProps,
    customization: OrderCustomization = {},
    newQuantity: number
  ) => {
    let identicalItemFound = newItem.value.isCustomizable
      ? basket.basketItems.find(
          i =>
            i.referenceId === newItem.referenceId &&
            i.type === newItem.type &&
            JSON.stringify(i.customization) === JSON.stringify(customization)
        )
      : basket.basketItems.find(
          i => i.referenceId === newItem.referenceId && i.type === newItem.type
        );

    if (identicalItemFound) {
      updateBasketItem(
        identicalItemFound.id,
        (identicalItemFound.quantity = newQuantity),
        customization
      );
    } else {
      setBasketLoading(true);
      const newBasket = await patchAddItemsByBasketId(basketId, {
        id: newItem.id,
        referenceId: newItem.referenceId || 0,
        type: newItem.type,
        label: newItem.label,
        quantity: newQuantity || 1,
        ...customization
      });
      setBasket(newBasket);
      setNamedLocalStorage('basket', JSON.stringify(newBasket));
      setBasketLoading(false);
    }
  };

  const removeBasketItem = async (id: number) => {
    setBasketLoading(true);
    const filteredBasketItems = await patchRemoveItemsById(basketId, id);
    updateBasket(filteredBasketItems);
    setBasketLoading(false);
  };

  const clearBasket = async (clearBasketId: boolean = false) => {
    let newBasket;
    if (clearBasketId) {
      setBasketLoading(true);
      await setNamedLocalStorage('basket', JSON.stringify(emptyBasket));
      getInitialBasket();
    } else {
      newBasket = await patchRemoveAllItemsByBasketId(basketId);
      setBasket(newBasket);
      setBasketLoading(false);
    }
  };

  useEffect(() => {
    getInitialBasket();
  }, []);

  return {
    basket,
    basketLoading,
    addBasketItem,
    updateBasketItem,
    getBasketItems,
    removeBasketItem,
    clearBasket,
    updateBasket
  };
};

export default useBasketState;
