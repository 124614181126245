import { AxiosResponse } from 'axios';
import api from '../utils/api';
import { ComposerType } from '../types/composer-group.type';

export const getAllComposerTiles = async (): Promise<
  AxiosResponse<ComposerType>
> => {
  try {
    return await api.get('/Composer/tiles');
  } catch (e) {
    return e.response;
  }
};
