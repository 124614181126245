import React, { MouseEvent } from 'react';
import * as S from './Lightbox.styles';

const Lightbox = ({
  showModal,
  imageUrl,
  onClick
}: {
  showModal: boolean;
  imageUrl?: string;
  onClick: () => (event: MouseEvent<HTMLInputElement>) => void;
}) => (
  <S.ModalBackground showModal={showModal} onClick={onClick()}>
    <S.Modal src={imageUrl} />
  </S.ModalBackground>
);

export default Lightbox;
