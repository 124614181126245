import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BasketModalContent } from '@k3imagine/self-serve-components';

import { Wrapper } from '../../BasketModalWizard.styles';
import { DeliverySelection } from '../../../../../types';
import GlobalContext from '../../../../../state/GlobalContext';

type PickupProps = {
  setPickupOption: Function;
  goBack: Function;
  goNext: Function;
};

const InlineWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

const InlineDiv = styled.div`
  min-width: 150px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;
`;

const PickupOrDelivery = ({ setPickupOption, goBack, goNext }: PickupProps) => {
  const { t } = useTranslation();
  const { visualProfileColors } = useContext(GlobalContext);

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Basket.DeliveryOptions')}
      buttonLabel={t('Continue')}
      buttonIcon="BackArrow"
      onIconButtonClicked={goBack}
    >
      <Wrapper>
        <InlineWrapper>
          <InlineDiv
            onClick={() => {
              setPickupOption(DeliverySelection.Pickup);
              goNext();
            }}
          >
            <div>
              <img
                height="128"
                width="128"
                alt=""
                src="https://k3imagine.blob.core.windows.net/selfserv/melabudin/pickup.png"
              />
            </div>
            <div>{t('Basket.Pickup')}</div>
          </InlineDiv>
          <InlineDiv
            onClick={() => {
              setPickupOption(DeliverySelection.Delivery);
              goNext();
            }}
          >
            <div>
              <img
                height="128"
                width="128"
                alt=""
                src="https://k3imagine.blob.core.windows.net/selfserv/melabudin/delivery.png"
              />
            </div>
            <div>{t('Basket.Deliver')}</div>
          </InlineDiv>
        </InlineWrapper>
      </Wrapper>
    </BasketModalContent>
  );
};

export default PickupOrDelivery;
