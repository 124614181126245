import api from '../utils/api';
import { Bundle } from '../types';

export const getBundleById = async (bundleId: number): Promise<Bundle> => {
  try {
    const { data } = await api.get(`/bundle/${bundleId}`);
    return data;
  } catch (e) {
    return e.response;
  }
};
